<template>
  <div
    class="easy-address__container"
    :class="{ isDefault: address.pr_298, isSwiped: isSwiped }"
    v-touch:swipe="swipeHandler"
  >
    <div class="easy-address__item">
      <div
        class="easy-address__header"
        v-touch:touchhold="touchHoldHandler"
        v-touch:tap.prevent="toggleExtended"
      >
        <div class="easy-address__type-icon">
          <img
            v-if="address.pr229 === 11212"
            src="@/assets/img/parcel/MyAddres.svg"
          />
          <img
            v-if="address.pr229 === 11028"
            src="@/assets/img/parcel/NewPost.svg"
          />
        </div>
        <div class="easy-address__title">{{ address.pr_222 }}</div>
        <div
          class="easy-address__default"
          v-touch:tap.stop="() => onSetDefault(address.ID)"
          v-html="starIcon"
        ></div>
      </div>
      <collapse-transition>
        <div v-if="isExtended" class="easy-address__content">
          <div class="easy-address__field">
            <div class="easy-address__field-name">
              {{ $t("myAddressStreetAndHouse") }}
            </div>
            <div class="easy-address__field-value">
              {{ address.pr233_Name }}
            </div>
          </div>
          <div v-if="address.pr375" class="easy-address__field">
            <div class="easy-address__field-name">
              {{ $t("addressApartmentName") }}
            </div>
            <div class="easy-address__field-value">
              {{ address.pr375 }}
            </div>
          </div>
          <div class="easy-address__field">
            <div class="easy-address__field-name">
              {{ $t("myAddressCityTitle") }}
            </div>
            <div class="easy-address__field-value">
              {{ address.pr364_Name }}
            </div>
          </div>
          <div class="easy-address__field">
            <div class="easy-address__field-name">
              {{ $t("myAddressStateTitle") }}
            </div>
            <div class="easy-address__field-value">
              {{ address.pr202_Name || address.pr_630 }}
            </div>
          </div>
          <div v-if="address.pr363_Name" class="easy-address__field">
            <div class="easy-address__field-name">
              {{ $t("postDeptANPu") }}
            </div>
            <div class="easy-address__field-value">
              {{ address.pr363_Name }}
            </div>
          </div>
          <div class="easy-address__field">
            <div class="easy-address__field-name">
              {{ $t("zipWrhUSA") }}
            </div>
            <div class="easy-address__field-value">
              {{ address.pr_256 }}
            </div>
          </div>
          <div class="easy-address__field">
            <div class="easy-address__field-name">
              {{ $t("myAddressFirstLast") }}
            </div>
            <div class="easy-address__field-value">
              {{ address.pr_471_Name }} {{ address.pr_225 }}
            </div>
          </div>
          <div class="easy-address__field">
            <div class="easy-address__field-name">{{ $t("phoneNumber") }}</div>
            <div class="easy-address__field-value">{{ address.s_11009 }}</div>
          </div>
        </div>
      </collapse-transition>
    </div>
    <div class="easy-address__actions">
      <div class="easy-address__action" @click="onRemove(address.ID)">
        {{ $t("myAddressRemove") }}
      </div>
    </div>

    <easy-bottom-sheet
      :isOpened.sync="isMenuOpened"
      :title="$t('parcelDeclineTitle')"
    >
      <div class="parcelCard__panel-btn" @click="onSetDefault(address.ID)">
        <img src="@/assets/img/Star.svg" />
        {{ $t("myAddressDefault") }}
      </div>
      <!-- <div class="parcelCard__panel-btn">
        <img src="@/assets/img/parcel/Copy.svg" />
        {{ $t("btnRepeat") }}
      </div> -->
      <div
        class="parcelCard__panel-btn parcelCard__panel-btn--remove"
        @click="onRemove(address.ID)"
      >
        <img src="@/assets/img/Choosing/Close.svg" />
        {{ $t("myAddressRemove") }}
      </div>
    </easy-bottom-sheet>
  </div>
</template>

<script>
import { starIcon } from "@/services/svg";
import EasyBottomSheet from "@/components/common/EasyBottomSheet.vue";
import { mapActions, mapGetters } from "vuex";
import eventsRegister from "@/services/events-register";

export default {
  name: "MyAddressItem",
  components: { EasyBottomSheet },
  props: {
    address: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters("parcels", ["parcelList"]),
    isCanBeDeleted() {
      const isDelivering = (p) =>
        [5020, 5017].includes(p.p_State_id) ||
        [5020].includes(p.State_id) ||
        [3, 4, 51, 52, 53, 61].includes(p.p_StepN);
      const parcelAddressIds = this.parcelList
        .filter(isDelivering)
        .map((p) => +p.pr489);
      return !parcelAddressIds.includes(+this.address.ID);
    },
  },
  data() {
    return {
      starIcon,
      isExtended: false,
      isSwiped: false,
      isMenuOpened: false,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions("address", ["setDefault", "removeAddress"]),
    toggleExtended() {
      this.isExtended = !this.isExtended;
    },
    touchHoldHandler() {
      this.isMenuOpened = true;
    },
    swipeHandler(direction) {
      if (direction === "left") this.isSwiped = true;
      if (direction === "right") this.isSwiped = false;
    },
    async onSetDefault(id) {
      if (this.isLoading) return false;
      this.isMenuOpened = false;
      this.isLoading = true;
      try {
        await this.setDefault(id);
        this.$root.$emit(eventsRegister.addMessage, {
          type: "success",
          message: this.$t("defaultAddressChangedAddr"),
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log("err", error);
      }
    },
    async onRemove(id) {
      if (!this.isCanBeDeleted) {
        return this.$root.$emit(eventsRegister.addMessage, {
          type: "error",
          message: this.$t("addressRemoveError"),
        });
      }
      if (this.isLoading) return false;
      this.isMenuOpened = false;
      this.isLoading = true;
      try {
        await this.removeAddress(id);
        this.$root.$emit(eventsRegister.addMessage, {
          type: "success",
          message: this.$t("addressDeleted"),
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log("err", error);
      }
    },
  },
};
</script>

<style lang="scss">
.easy-address {
  &__container {
    position: relative;
  }
  &__item {
    padding-left: 14px;
    padding-right: 14px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 22px;
    position: relative;
    z-index: 1;
    transition: transform 0.3s;

    .isSwiped & {
      transform: translateX(-156px);
    }
  }

  &__header {
    display: flex;
    height: 62px;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    color: #282828;
    font-family: "Helvetica Neue Medium";
    flex: 1 auto;
    padding-left: 16px;
    padding-top: 2px;
  }

  &__type-icon {
    height: 100%;
    display: flex;
    align-items: center;

    img {
      display: block;
    }
  }
  &__default {
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      fill: #bfbfbf;
      .isDefault & {
        fill: rgb(250, 179, 20);
      }
    }
  }

  &__actions {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #f1f1f1;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
  }

  &__action {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 2px;
    line-height: 1;
    font-size: 14px;
    color: #ef8143;
    display: flex;
    align-items: center;
  }

  &__field {
    border-top: 1px solid #f1f1f1;
    padding-top: 8px;
    padding-bottom: 7px;

    &:last-child {
      padding-bottom: 10px;
    }
  }

  &__field-name {
    font-size: 12px;
    color: #696969;
    line-height: 20px;
  }
  &__field-value {
    font-size: 16px;
    color: #282828;
    line-height: 20px;
  }
}
</style>
