<template>
  <div class="address-page">
    <div class="address-page__header" :class="{ isLight: listIsEmpty }">
      <span class="address-page__back" @click="$router.push('/')" v-html="leftArr"></span>
      <div class="address-page__title">{{$t('myAddressTitle')}}</div>
    </div>
    <div class="address-page__content">
      <div class="address-page__empty" v-if="listIsEmpty" key="address-empty">
        <div class="address-page__empty-title">{{$t('myAddressTitle')}}</div>
        <div class="address-page__empty-subtitle">
          {{$t('myAddressDescription')}}
        </div>
        <router-link to="/address-list/new" class="btnWideRound is-second">{{$t('addAddress')}}</router-link>
      </div>
      <div v-else key="address-filled" class="address-page__filled">
        <div class="address-page__filled-list">
          <my-address-item
            v-for="(itm) in addressList"
            :address="itm"
            :key="itm.ID"
          />
          <router-link to="/address-list/new" class="btnWideRound is-second">{{$t('addAddress')}}</router-link>
        </div>
        <div class="address-page__filled-info">
          {{$t('myAddressTitle')}} - {{$t('myAddressSubDescription')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { leftArr } from "@/services/svg";

import MyAddressItem from "./MyAddressItem.vue";

export default {
  name: "MyAddress",
  components: {
    MyAddressItem,
  },
  data() {
    return {
      leftArr,
    };
  },
  created() {
    if (!this.addressList.length) {
      this.getAddressList();
    }
  },
  computed: {
    ...mapGetters("address", ["addressList"]),
    listIsEmpty() {
      return !this.addressList.length;
    },
  },
  methods: {
    ...mapActions("address", ["getAddressList"]),
  }
};
</script>

<style lang="scss">
@import "@/styles/views/_address.scss";
.address-page {
  &__empty {
    flex: 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 40px;
    position: relative;
    box-sizing: border-box;

    &:after {
      left: -16px;
      right: 16px;
      bottom: 220px;
      content: "";
      height: 570px;
      position: absolute;
      background: url("~@/assets/img/empty-address.svg") bottom right no-repeat;
    }

    &-title {
      font-family: "Helvetica Neue Medium";
      font-size: 20px;
      margin-bottom: 16px;
      text-align: center;
    }
    &-subtitle {
      margin-bottom: 26px;
      text-align: center;
      font-size: 14px;
      line-height: 1.4;
      color: #765d2e;
    }
  }

  &__filled {
    flex: 1 auto;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 40px;
    position: relative;
    box-sizing: border-box;
  }

  &__filled-info {
    font-size: 12px;
    line-height: 1.3;
    text-align: center;
    padding-top: 32px;
    color: #765d2e;
  }

  &__filled-list {
    flex: 1 auto;
  }
}
</style>